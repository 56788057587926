import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  AiOutlineDownload,
  AiOutlineClose,
  AiFillFolder,
  AiFillFolderOpen,
  AiFillFile,
} from 'react-icons/ai';

// Define colorMap outside the component to prevent re-creation on every render
const colorMap = [
  '#7289da',
  '#99aab5',
  '#2ecc71',
  '#e74c3c',
  '#f1c40f',
  '#9b59b6',
  '#e67e22',
  '#e91e63',
  '#1abc9c',
  '#34495e',
  '#e84393',
  '#8e44ad',
  '#d35400',
  '#2980b9',
  '#16a085',
  '#f39c12',
  '#c0392b',
  '#27ae60',
  '#bdc3c7',
  '#7f8c8d',
  '#fdcb6e',
];

function ExecutorCard({ executor }) {
  const [isHovered, setIsHovered] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState({});

  const truncateName = (name, length = 20) => {
    if (name.length > length) {
      return name.slice(0, length) + '...';
    }
    return name;
  };

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#252525',
    borderRadius: '16px',
    padding: '20px',
    textAlign: 'left',
    transition: 'transform 0.3s ease, boxShadow 0.3s ease',
    cursor: 'pointer',
    boxShadow: isHovered
      ? '0 15px 30px rgba(0,0,0,0.7)'
      : '0 8px 15px rgba(0, 0, 0, 0.4)',
    borderLeft: `3px solid ${executor.color}`,
    overflow: 'hidden',
    transform: isHovered ? 'translateY(-10px)' : 'translateY(0)',
    position: 'relative',
  };

  const renderFileStructure = (files, currentPath = '', indentLevel = 0) => {
    return (
      <ul
        style={{
          listStyleType: 'none',
          paddingLeft: indentLevel * 10,
          fontSize: '14px',
          color: '#d3d3d3',
        }}
      >
        {Object.entries(files).map(([key, value]) => {
          const newPath = currentPath ? `${currentPath}/${key}` : key;
          if (typeof value === 'object') {
            const isExpanded = expandedFolders[newPath];
            return (
              <li key={newPath} style={{ marginBottom: '5px' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  title={key}
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedFolders((prev) => ({
                      ...prev,
                      [newPath]: !prev[newPath],
                    }));
                  }}
                >
                  {isExpanded ? (
                    <AiFillFolderOpen style={{ marginRight: '8px', color: '#f1c40f' }} />
                  ) : (
                    <AiFillFolder style={{ marginRight: '8px', color: '#f1c40f' }} />
                  )}
                  {truncateName(key)}
                </div>
                {isExpanded && renderFileStructure(value, newPath, indentLevel + 1)}
              </li>
            );
          } else {
            return (
              <li key={newPath} style={{ marginBottom: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }} title={value}>
                  <AiFillFile style={{ marginRight: '8px', color: '#3498db' }} />
                  {truncateName(value)}
                </div>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p
        style={{
          fontSize: '20px',
          fontWeight: '600',
          color: '#ffffff',
          marginBottom: '10px',
        }}
      >
        {executor.name}
      </p>
      <div
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          marginTop: '10px',
          scrollbarWidth: 'thin',
          scrollbarColor: '#555 #252525',
        }}
        className="custom-scrollbar"
      >
        {renderFileStructure(executor.files)}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          width: '40px',
          height: '40px',
          backgroundColor: executor.color,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#ffffff',
          fontSize: '20px',
          fontWeight: 'bold',
          boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          window.location.href = `https://skidware.dev/zips/${executor.name}.zip`;
        }}
        title="Download ZIP"
      >
        <AiOutlineDownload />
      </div>
    </div>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [executors, setExecutors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suggestion, setSuggestion] = useState('');

  // Ref to prevent multiple fetches
  const hasFetchedFiles = useRef(false);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      axios
        .get('https://skidware.dev/api/list-zips')
        .then((response) => {
          const files = response.data.files.filter((file) => file.endsWith('.zip'));

          const executorsData = files.map((file, index) => ({
            name: file.replace('.zip', ''),
            color: colorMap[index % colorMap.length],
            files: {},
          }));

          setExecutors(executorsData);
        })
        .catch((error) => console.error('Error fetching executor list:', error))
        .finally(() => setIsLoading(false));
    }, 3000);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    // Prevent fetching files multiple times
    if (executors.length > 0 && !hasFetchedFiles.current) {
      const fetchAllExecutors = async () => {
        try {
          const allExecutorPromises = executors.map((executor) =>
            axios.get(`https://skidware.dev/api/zip-contents/${executor.name}.zip`)
          );

          const allResponses = await Promise.all(allExecutorPromises);

          const newExecutors = executors.map((executor, idx) => {
            const response = allResponses[idx];
            const files = response.data.contents.reduce((acc, file) => {
              // Normalize the path by replacing multiple slashes with a single slash
              const normalizedPath = file.replace(/\/+/g, '/');
              const parts = normalizedPath.split('/').filter(Boolean);

              let current = acc;
              for (let i = 0; i < parts.length; i++) {
                const part = parts[i];
                const isLastPart = i === parts.length - 1;
                const isDirectory = file.endsWith('/') && isLastPart;

                if (isLastPart) {
                  if (isDirectory) {
                    if (!current[part]) {
                      current[part] = {};
                    }
                  } else {
                    current[part] = part;
                  }
                } else {
                  if (!current[part]) {
                    current[part] = {};
                  }
                  current = current[part];
                }
              }

              return acc;
            }, {});

            return {
              ...executor,
              files,
            };
          });

          setExecutors(newExecutors);
          hasFetchedFiles.current = true; // Mark as fetched
        } catch (error) {
          console.error('Error fetching contents for executors:', error);
        }
      };

      fetchAllExecutors();
    }
  }, [executors.length]);

  const handleSuggestionSubmit = () => {
    axios
      .post('https://skidware.dev/api/suggestion', { suggestion })
      .then((response) => {
        console.log('Suggestion submitted:', response.data);
        setIsModalOpen(false);
        setSuggestion('');
      })
      .catch((error) => console.error('Error submitting suggestion:', error));
  };

  return (
    <div
      style={{
        fontFamily: "'Poppins', sans-serif",
        margin: '0',
        padding: '0',
        backgroundColor: '#0f0f0f',
        color: '#ffffff',
        overflow: 'hidden',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Loading Screen */}
      <div
        style={{
          display: isLoading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#0f0f0f',
          color: '#ffffff',
          zIndex: '10',
        }}
      >
        <h1
          style={{
            fontSize: '64px',
            fontWeight: '700',
            margin: '0',
            letterSpacing: '2px',
            color: '#ffffff',
            textShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
            animation: 'fadeInUp 1.5s ease',
          }}
        >
          Skidware
        </h1>
        <div
          style={{
            width: '80%',
            maxWidth: '600px',
            height: '10px',
            backgroundColor: '#252525',
            borderRadius: '10px',
            marginTop: '20px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: '0%',
              height: '100%',
              backgroundImage: 'linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%)',
              animation: 'loadingFill 3s forwards',
            }}
          ></div>
        </div>
      </div>

      {/* Header */}
      <header
        style={{
          backgroundColor: '#0f0f0f',
          padding: '10px 20px',
          textAlign: 'center',
          color: '#ffffff',
          boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1
          style={{
            fontSize: '36px',
            fontWeight: '700',
            margin: '0',
            letterSpacing: '2px',
            color: '#ffffff',
          }}
        >
          Skidware
        </h1>
        <button
          onClick={() => setIsModalOpen(true)}
          style={{
            position: 'absolute',
            right: '20px',
            backgroundColor: '#e74c3c',
            color: '#ffffff',
            border: 'none',
            borderRadius: '8px',
            padding: '12px 30px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Suggest Something
        </button>
      </header>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '20px',
          padding: '20px 5%',
          backgroundColor: '#1a1a1a',
          boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.8)',
          flex: '1',
          minHeight: '60vh',
          transition: 'opacity 1s ease-in-out 0.5s',
          opacity: isLoading ? 0 : 1,
        }}
      >
        {executors.map((executor) => (
          <ExecutorCard key={executor.name} executor={executor} />
        ))}
      </div>

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '20',
          }}
        >
          <div
            style={{
              backgroundColor: '#252525',
              padding: '40px',
              borderRadius: '16px',
              width: '400px',
              position: 'relative',
              color: '#ffffff',
            }}
          >
            <AiOutlineClose
              onClick={() => setIsModalOpen(false)}
              style={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                cursor: 'pointer',
                color: '#ffffff',
                fontSize: '20px',
              }}
            />
            <h2 style={{ marginBottom: '20px' }}>Submit Your Suggestion</h2>
            <textarea
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              placeholder="Enter your suggestion here..."
              style={{
                maxWidth: 'calc(100% - 20px)',
                minWidth: 'calc(100% - 20px)',
                height: '100px',
                maxHeight: '100px',
                borderRadius: '8px',
                padding: '10px',
                backgroundColor: '#1a1a1a',
                border: 'none',
                color: '#ffffff',
                marginBottom: '20px',
              }}
            />
            <button
              onClick={handleSuggestionSubmit}
              style={{
                backgroundColor: '#e74c3c',
                color: '#ffffff',
                border: 'none',
                borderRadius: '8px',
                padding: '10px 15px',
                cursor: 'pointer',
                fontWeight: 'bold',
                width: '100%',
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      <footer
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#0f0f0f',
          color: '#b0b0b0',
        }}
      >
        <p>&copy; 2024 Skidware. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;

// Keyframes and Custom Scrollbar Styles
const loadingKeyframes = `
  @keyframes loadingFill {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Custom scrollbar styling */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #252525;
  }
`;

// Add keyframes and custom scrollbar styles to the document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = loadingKeyframes;
document.head.appendChild(styleSheet);
